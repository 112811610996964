import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Chart } from 'chart.js';
import { SharedService } from 'app/shared.service';

declare var $: any;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})

export class DetailsComponent implements OnInit {
  IdGraph = 0;
  title = "";
  red = 0;
  yellow = 0;

  selectedDay = 0;
  public canvas: any;
  public ctx;
  public chart;
  color = { "Green": "rgba(59,204,66,0.5)", "Yellow": "rgba(252,226,81,0.5)", "Red": "rgba(214,97,71,0.5)" };
  colorBlock = { "Green": "rgba(42,145,47,1)", "Yellow": "rgba(252,196,53,1)", "Red": "rgba(214,54,54,1)" };


  currentMonth = parseInt(moment().format("M")) - 1;
  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  constructor(private router: Router, private route: ActivatedRoute, private service: SharedService) {
    this.route.params.subscribe((params) => {
      this.IdGraph = parseInt(params.IdGraph);
    });
  }



  closeModal() {
    function closeModal() {
      document.getElementById("modal").style.display = "none";
      document.getElementById("modal").classList.remove("show");
    }

    closeModal();

  }

  graphClickEvent(event, array) {

    function ordinal(number: number) {
      switch (number) {
        case 1:
        case 21:
          return number + 'st';
        case 2:
        case 22:
          return number + 'nd';
        case 3:
        case 23:
          return number + 'rd';
        default:
          return number + 'th';
      }
    }

    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    function openModal() {
      document.getElementById("modal").style.display = "block";
      document.getElementById("modal").classList.add("show");
    }

    let currentMonth = parseInt(moment().format("M")) - 1;;
    let a = window.location.href;
    if (array[0]) {
      // window.location.href = "/#/dashboard/details/arl-status;Day=" + array[0]._model.label + ";IdGraph=" + a.split("=")[1];
      // setTimeout(() => {
      //   location.reload();
      // }, 0);
      this.selectedDay = array[0]._model.label;
      $("#exampleModalLabel").html(ordinal(array[0]._model.label) + " " + months[currentMonth])

      openModal();//background-color: rgba(255,255,255,0.7);


    }
  }

  loadGraph() {
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    function ordinal(number) {
      switch (number) {
        case 1:
        case 21:
          return number + 'st';
        case 2:
        case 22:
          return number + 'nd';
        case 3:
        case 23:
          return number + 'rd';
        default:
          return number + 'th';
      }
    }

    let currentMonth = parseInt(moment().format("M")) - 1;

    this.canvas = document.getElementById("echart");
    this.ctx = this.canvas.getContext("2d");

    let now = new Date(); //CALCOLO GIORNI NEL MESE
    let dat = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    let labels = [];
    for (let i = 1; i <= dat; i++) {
      labels.push(i);
    }

    let red = this.red;
    let yellow = this.yellow;
    let color = this.color;
    let colorBlock = this.colorBlock;
    let id = { IdUser: this.IdGraph };
    this.service.GetDataGraph(id).subscribe({
      next: data => {
        let cumulativeData = [];
        let datas = [];
        let backgroundColor = [];
        let borderColor = [];
        let cumulative = 0;
        let lastMonth = [];
        $.each(data, function (key, value) {
          let daaat = new Date(value["Date"]);
          // if (daaat.toLocaleDateString().split("/")[0] == (key + 1)) { //Controllo se viene saltato un giorno
          cumulative += value["CurrentValue"];
          let calc = cumulative / parseInt(key + 1);
          cumulativeData.push(parseFloat(calc.toString()).toFixed(2));
          datas.push(parseFloat(value["CurrentValue"]).toFixed(2));
          lastMonth.push(parseFloat(value["LastMonthValue"]).toFixed(2));

          if (parseFloat(value["CurrentValue"]) <= red) {
            backgroundColor.push(color["Red"]);
            borderColor.push(colorBlock["Red"]);
          } else if (parseFloat(value["CurrentValue"]) <= yellow) {
            backgroundColor.push(color["Yellow"]);
            borderColor.push(colorBlock["Yellow"]);
          } else {
            backgroundColor.push(color["Green"]);
            borderColor.push(colorBlock["Green"]);
          }
          // } else {
          //   datas.push(0);
          //   cumulativeData.push(0);
          //   lastMonth.push(0);
          //   backgroundColor.push(0);
          //   borderColor.push(0);

          // }

        });
        this.chart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: labels,
            datasets: [{
              label: 'Daily KPI',
              data: datas,
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              borderWidth: 1,
              stack: 'combined',
              type: 'bar'
            },
            {
              label: 'Cumulative KPI',
              data: cumulativeData,
              backgroundColor: "rgba(73,100,252,1)",
              borderColor: 'rgba(73,100,252,1)',
              fill: false
            }, {
              label: 'Last Month KPI',
              data: lastMonth,
              borderDash: [5, 5],
              borderWidth: 1,
              borderColor: [
                'red'
              ],
              fill: false
            }]
          },

          options: {
            responsive: true,
            onClick: this.graphClickEvent,
            tooltips: {
              mode: "index",
              titleFontSize: 20,
              bodyFontSize: 17,
              callbacks: {
                title: function (t, d) {
                  return ordinal(t[0].xLabel) + " " + months[currentMonth];
                },
                label: function (t, d) {
                  if (t.datasetIndex === 0) {
                    return 'Daily KPI: ' + t.yLabel + "%";
                  } else if (t.datasetIndex === 1) {
                    return 'Cumulative KPI: ' + t.yLabel + "%";
                  }
                }
              }
            },
            scales: {

              xAxes: [{
                //     stacked: true, 
                gridLines: { display: false },
                scaleLabel: {
                  display: true,
                  labelString: 'Day of the current Month',
                  // fontSize: 20,
                  fontFamily: 'Montserrat'
                }
              }],
              yAxes: [{
                //      stacked: true, 
                scaleLabel: {
                  display: true,
                  labelString: 'KPI [%]',
                  fontFamily: 'Montserrat',
                },
                display: true,
                ticks: {
                  stepSize: 1,
                  max: 100,
                  min: 90,
                  callback: function (value) {
                    return (value) + '%';
                  }
                }
              }]
            },

          }
        });
        $('#cover-spin').hide();
      },
      error: error => {
        $('#cover-spin').hide();
      }
    })

  }

  ngOnInit() {
    $('#cover-spin').show();
    switch (this.IdGraph) {
      case 1:
        this.title = "Train Service Availability";
        $(".airport").prop("hidden", false);
        $("highspeed").prop("hidden", true);
        this.red = 98.5;
        this.yellow = 99;
        break;
      case 2:
        this.title = "Train Service Punctuality";
        $(".airport").prop("hidden", false);
        $(".highspeed").prop("hidden", true);
        this.red = 96.5;
        this.yellow = 97;
        break;
      case 3:
        this.title = "Train Availability";
        $(".airport").prop("hidden", false);
        $(".highspeed").prop("hidden", true);
        this.red = 96.5;
        this.yellow = 97;
        break;
      case 4:
        this.title = "Train Service Availability";
        $(".highspeed").prop("hidden", false);
        $(".airport").prop("hidden", true);
        this.red = 98.5;
        this.yellow = 99;
        break;
      case 5:
        this.title = "Train Service Punctuality";
        $(".highspeed").prop("hidden", false);
        $(".airport").prop("hidden", true);
        this.red = 96.5;
        this.yellow = 97;
        break;
      case 6:
        this.title = "Train Availability";
        $(".highspeed").prop("hidden", false);
        $(".airport").prop("hidden", true);
        this.red = 96.5;
        this.yellow = 97;
        break;
      default:
        $(".airport").prop("hidden", false);
        $(".highspeed").prop("hidden", true);
        this.title = "Train Stat";
    }
    let wind = window.innerHeight / 2;

    // if (/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //   $(".card-body").css("min-height",wind.toString()+"px");
    // }else{
    //   // $("#echart").css("min-height",wind.toString()-200+"px");

    // }

    this.loadGraph();

    $("#modal").on('shown', function () {
      alert("I want this to appear after the modal has opened!");
    });
  }


}

