import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/api.service';
import { CookieService } from 'ngx-cookie-service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  constructor(private cookieService: CookieService, private service: ApiService) { }
  userdata: any;
  userdat: any;
  ngOnInit(): void {
    $('#sidebarCollapse').on('click', function () {
      $('#sidebar, #content').toggleClass('active');
    });

    $(".nav-link").click(function (this: any) {
      $(".nav-link").removeClass("bg-light");
      $(this).addClass("bg-light");
    });

    var cook = this.cookieService.get("session");

    let data = { Hash: cook };
    console.log(data)
    this.service.getUserByHash(data).subscribe({
      next: data => {
        console.log(data)
        this.userdat = data;
        this.userdata = this.userdat[0];
        console.log(this.userdata)


      },
      error: error => {
        this.userdata = error.message;
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })

  }

  logout() {

    Swal.fire({
      title: 'Sei sicuro?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        $('#cover-spin').show();
        this.cookieService.delete("session");
        window.location.reload();
      }
    })

  }

}
