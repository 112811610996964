import { Routes } from '@angular/router';
import { AcquirentiComponent } from './pages/compravendite/acquirenti/acquirenti.component';
import { VenditoriComponent } from './pages/compravendite/venditori/venditori.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProposteComponent } from './pages/affitti/proposte/proposte.component';
import { RichiesteComponent } from './pages/affitti/richieste/richieste.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ImmobiliComponent } from './pages/immobili/immobili.component';
import { AcquirentiAggiungiComponent } from './pages/compravendite/acquirenti-aggiungi/acquirenti-aggiungi.component';
import { AcquirentiModificaComponent } from './pages/compravendite/acquirenti-modifica/acquirenti-modifica.component';
import { VenditoriAggiungiComponent } from './pages/compravendite/venditori-aggiungi/venditori-aggiungi.component';
import { VenditoriModificaComponent } from './pages/compravendite/venditori-modifica/venditori-modifica.component';
import { RichiesteAggiungiComponent } from './pages/affitti/richieste-aggiungi/richieste-aggiungi.component';
import { RichiesteModificaComponent } from './pages/affitti/richieste-modifica/richieste-modifica.component';
import { ProposteAggiungiComponent } from './pages/affitti/proposte-aggiungi/proposte-aggiungi.component';
import { ProposteModificaComponent } from './pages/affitti/proposte-modifica/proposte-modifica.component';
import { ImmobiliAggiungiComponent } from './pages/immobili/immobili-aggiungi/immobili-aggiungi.component';
import { ImmobiliModificaComponent } from './pages/immobili/immobili-modifica/immobili-modifica.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'homepage',
    pathMatch: 'full',
  },{
    path: 'homepage',
    component: HomepageComponent,
  }, {
    path: '',
    component: NavbarComponent,
    children: [
        {
      path: 'proposte',
      component: ProposteComponent
       },
        {
      path: 'proposte/aggiungi',
      component: ProposteAggiungiComponent
       },
        {
      path: 'proposte/modifica',
      component: ProposteModificaComponent
       },
        {
      path: 'venditori',
      component: VenditoriComponent
       },
        {
      path: 'venditori/aggiungi',
      component: VenditoriAggiungiComponent
       },
        {
      path: 'venditori/modifica',
      component: VenditoriModificaComponent
       },
       {
        path: 'richieste',
        component: RichiesteComponent
         },
       {
        path: 'richieste/aggiungi',
        component: RichiesteAggiungiComponent
         },
       {
        path: 'richieste/modifica',
        component: RichiesteModificaComponent
         },
       {
        path: 'acquirenti',
        component: AcquirentiComponent
         },
       {
        path: 'acquirenti/aggiungi',
        component: AcquirentiAggiungiComponent
         },
       {
        path: 'acquirenti/modifica',
        component: AcquirentiModificaComponent
         },
       {
        path: 'immobili',
        component: ImmobiliComponent
         },
       {
        path: 'immobili/aggiungi',
        component: ImmobiliAggiungiComponent
         },
       {
        path: 'immobili/modifica',
        component: ImmobiliModificaComponent
         },
       {
        path: 'profile',
        component: ProfileComponent
         },
       {
        path: 'calendar',
        component: CalendarComponent
         },
]},
{
  path: '**',
  redirectTo: 'homepage'
}
]
