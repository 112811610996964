import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  readonly APIUrl = "/api";
  constructor(private http: HttpClient) { }

  signIn(val: any) {
    return this.http.post(this.APIUrl + "/user/signin/", val);
  }
  getUserByHash(val: any) {
    return this.http.post(this.APIUrl + "/user/getbyhash/", val);
  }
  getRegion() {
    return this.http.get(this.APIUrl + "/data/getregion/");
  }
  getProvince(val: any) {
    return this.http.post(this.APIUrl + "/data/getprovince/", val);
  }
  getCity(val: any) {
    return this.http.post(this.APIUrl + "/data/getcity/", val);
  }
  insertBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/insertbuyer/", val);
  }
  updateBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/updatebuyer/", val);
  }
  deleteBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/deletebuyer/", val);
  }
  getUser() {
    return this.http.get(this.APIUrl + "/user/");
  }
  updateUser(val: any) {
    return this.http.post(this.APIUrl + "/user/update/", val);
  }
  insertUser(val: any) {
    return this.http.post(this.APIUrl + "/user/insert/", val);
  }
  deleteUser(val: any) {
    return this.http.post(this.APIUrl + "/user/delete/", val);
  }
  changeUser(val: any) {
    return this.http.post(this.APIUrl + "/user/change/", val);
  }
  changePswUser(val: any) {
    return this.http.post(this.APIUrl + "/user/changepsw/", val);
  }
  getBuyer() {
    return this.http.get(this.APIUrl + "/data/getallbuyer");
  }
  getAllCities() {
    return this.http.get(this.APIUrl + "/data/getallcity/");
  }
  getFilterBuyer(val: any) {
    return this.http.post(this.APIUrl + "/data/selectfiltertbuyer/", val);
  }
  getBuyerById(val: any) {
    return this.http.post(this.APIUrl + "/data/getbuyerbyid/", val);
  }


  insertSeller(val: any) {
    return this.http.post(this.APIUrl + "/venditori/", val);
  }
  updateSeller(val: any) {
    return this.http.post(this.APIUrl + "/venditori/update/", val);
  }
  deleteSeller(val: any) {
    return this.http.post(this.APIUrl + "/venditori/delete/", val);
  }
  getSeller() {
    return this.http.get(this.APIUrl + "/venditori/");
  }
  getFilterSeller(val: any) {
    return this.http.post(this.APIUrl + "/venditori/selectfilterseller/", val);
  }
  getSellerById(val: any) {
    return this.http.post(this.APIUrl + "/venditori/getbyid/", val);
  }


  insertRequests(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/", val);
  }
  updateRequests(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/update/", val);
  }
  deleteRequests(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/delete/", val);
  }
  getRequests() {
    return this.http.get(this.APIUrl + "/richiesta/");
  }
  getFilterRequests(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/selectfilterrequests/", val);
  }
  getRequestsById(val: any) {
    return this.http.post(this.APIUrl + "/richiesta/getbyid/", val);
  }


  insertAppointment(val: any) {
    return this.http.post(this.APIUrl + "/appointment/", val);
  }
  updateAppointment(val: any) {
    return this.http.post(this.APIUrl + "/appointment/update/", val);
  }
  deleteAppointment(val: any) {
    return this.http.post(this.APIUrl + "/appointment/delete/", val);
  }
  getAppointments() {
    return this.http.get(this.APIUrl + "/appointment/");
  }
  getAppointmentById(val: any) {
    return this.http.post(this.APIUrl + "/appointment/getbyid/", val);
  }


  insertProposal(val: any) {
    return this.http.post(this.APIUrl + "/proposta/", val);
  }
  updateProposal(val: any) {
    return this.http.post(this.APIUrl + "/proposta/update/", val);
  }
  deleteProposal(val: any) {
    return this.http.post(this.APIUrl + "/proposta/delete/", val);
  }
  getProposals() {
    return this.http.get(this.APIUrl + "/proposta/");
  }
  getFilterProposals(val: any) {
    return this.http.post(this.APIUrl + "/proposta/selectfilterproposals/", val);
  }
  getProposalById(val: any) {
    return this.http.post(this.APIUrl + "/proposta/getbyid/", val);
  }


  insertProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/", val);
  }
  uploadProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/upload", val, {reportProgress: true, observe: 'events'});
  }
  updateProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/update/", val);
  }
  deleteProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/delete/", val);
  }
  getProperty() {
    return this.http.get(this.APIUrl + "/propieta/");
  }
  getFilterProperty(val: any) {
    return this.http.post(this.APIUrl + "/propieta/selectfilterproperty/", val);
  }
  getPropertyById(val: any) {
    return this.http.post(this.APIUrl + "/propieta/getbyid/", val);
  }
}
