import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  readonly APIUrl = "/api";
  constructor(private http: HttpClient) { }

  getDepList(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/department");
  }
  updateDeparment(val: any) {
    return this.http.put(this.APIUrl + "/department", val);
  }
  deleteDeparment(val: any) {
    return this.http.delete(this.APIUrl + "/department", val);
  }
  signIn(val: any) {
    return this.http.post(this.APIUrl + "/user/signin/", val);
  }
  GetUserById(val: any) {
    return this.http.post(this.APIUrl + "/user/getbyid/", val);
  }
  GetUserByUsername(val: any) {
    return this.http.post(this.APIUrl + "/user/getbyusername/", val);
  }
  GetDesirioData(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/data/getdesirio/");
  }
  GetDesirioDataById(val: any) {
    return this.http.post(this.APIUrl + "/data/getdesirobytrain", val);
  }

  // TRAIN SERVICE AVAILABILITY
  GetCurrentMonthTsa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServiceAvailability");
  }
  GetCurrentMonthMidTsa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServiceAvailability/getCurrentMonthMid");
  }
  GetLastMonthMidTsa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServiceAvailability/getLastMonthMid");
  }
  GetDailyMidTsa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServiceAvailability/getDailyMid");
  }
  GetDailyMonthMidTsa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServiceAvailability/getDailyMonthMid");
  }

  // TRAIN AVAILABILITY
  GetCurrentMonthTa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainAvailability");
  }
  GetCurrentMonthMidsa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainAvailability/getCurrentMonthMid");
  }
  GetLastMonthMidTa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainAvailability/getLastMonthMid");
  }
  GetDailyMidTa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainAvailability/getDailyMid");
  }
  GetDailyMonthMidTa(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainAvailability/getDailyMonthMid");
  }

  // TRAIN SERVICE PUNCTUALITY
  GetCurrentMonthTsp(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServicePunctuality");
  }
  GetCurrentMonthMidTsp(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServicePunctuality/getCurrentMonthMid");
  }
  GetLastMonthMidTsp(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServicePunctuality/getLastMonthMid");
  }
  GetDailyMidTsp(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServicePunctuality/getDailyMid");
  }
  GetDailyMonthMidTsp(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/TrainServicePunctuality/getDailyMonthMid");
  }


  GetLastMonthMidAll(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/data/getLastMonthMidAll");
  }
  GetDataGraph(IdGraph: any) {
    return this.http.post(this.APIUrl + "/data/getDataGraph", IdGraph);
  }
  GetTrainStatusByDate(data: any) {
    return this.http.post(this.APIUrl + "/data/getFleetStatusbyDate", data);
  }
  GetTrainStatus(): Observable<any[]> {
    return this.http.get<any>(this.APIUrl + "/data/getFleetStatus");
  }

  GetLastTrainRecord(data: any){
    return this.http.post<any>(this.APIUrl + "/data/getlastrecordbytrain", data);
  }
}
