import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/api.service';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-venditori',
  templateUrl: './venditori.component.html',
  styleUrls: ['./venditori.component.css']
})
export class VenditoriComponent implements OnInit {
  Users: any;
  Cities: any;
  constructor(private service: ApiService, private route: ActivatedRoute,private cookieService: CookieService) { }
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  ngOnInit(): void {
    this.loadData();
    this.loadUsers();
    this.loadCities();

    setTimeout(() => {
      $(".btn-primary").click();

    }, 100);

    $('#mytab tbody').on('click', 'a', function (e: any) {
      console.log($(e.currentTarget).attr("data-id"));

      if ($(e.currentTarget).attr("data-type") == 'delete') {
        Swal.fire({
          title: 'Sei sicuro?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si!',
          cancelButtonText: 'No'
        }).then((result) => {
          if (result.isConfirmed) {
            // this.delete($(e.currentTarget).attr("data-id"));
            let s = $(e.currentTarget).attr("data-id");
            $.ajax({
              url: '/api/venditori/delete/',
              type: 'POST',  // http method
              data: JSON.stringify({ IdSeller : s }),  // data to submit
              dataType: 'json',
              contentType: "application/json; charset=utf-8",
              success: function () {
                Swal.fire({
                  icon: 'success',
                  title: 'Eliminazione completata!',
                  showConfirmButton: false,
                  timer: 1200
                }).then((result) => {
                  window.location.reload();

                })
              },
              error: function (req: any, status: any) {
                console.log(req);
                console.log(status);
              }
            });
          }
        })
      } else {
        window.location.href = "/#/venditori/modifica?id=" + $(e.currentTarget).attr("data-id");
      }


    });
  }

  loadData() {

    var idu = this.fixdata($("#nome option:selected").val());
    var city = this.fixdata($("#city option:selected").val());
    var maxprice = this.fixdata($("#maxprice").val());
    var minprice = this.fixdata($("#minprice").val());

    let formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',

    });

    let data = {
      IdSeller: idu,
      City: city,
      Richiesta: minprice,
      MaxRichiesta: maxprice
    }
    console.log(data)
    $('#cover-spin').show();
    this.service.getFilterSeller(data).subscribe({
      next: data => {
        console.log(data)
        let cont = "";
        $.each(data, function (index: any, value: any) {
          cont += "<tr><th>" + (index + 1) + "</th><td>" + value["Name"] + "</td><td>" + value["Surname"] + "</td><td>(+39) " + value["Phone"] + "</td><td>" + value["Email"] + "</td><td>" + formatter.format(value["Request"]) + "</td><td>" + value["CityName"] + " (" + value["Code"] + ") </td><td> <a href='javascript:void(0);' data-id='" + value["IdSeller"] + "' style='color: blue;' data-type='edit'><i class='fas fa-edit'></i></a> &nbsp; <a class='rolecontrol' href='javascript:void(0);' data-id='" + value["IdSeller"] + "' data-type='delete' style='color: red;'><i class='fas fa-trash-alt'></i></a></td></tr>";
        });
        $("#tbody").html(cont);
        this.getUserRole();

        $('#cover-spin').hide();
      },
      error: error => {
        $('#cover-spin').hide();
      }
    })

  }

  loadUsers() {
    this.service.getSeller().subscribe({
      next: data => {
        console.log(data)
        this.Users = data;
      }
    })
  }

  fixdata(val: any) {
    if (val == '') {
      return -1;
    } else {
      return val;
    }
  }

  loadCities() {
    this.service.getAllCities().subscribe({
      next: data => {
        this.Cities = data;
      }
    })
  }

  getUserRole() {
    var cook = this.cookieService.get("session");

    let data = { Hash: cook };
    console.log(data)
    this.service.getUserByHash(data).subscribe({
      next: data => {

        if (data[0]["Role"] != "Amministratore") {
          $(".rolecontrol").prop("hidden", true);
        }

      },
      error: error => {
      }
    })
  }
}
