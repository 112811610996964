import { Component, OnInit } from '@angular/core';
import { SharedService } from 'app/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { Md5 } from 'ts-md5';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [Md5]
})
export class LoginComponent implements OnInit {

  constructor(private service: SharedService, private cookie: CookieService) { }

  userdata;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  ngOnInit(): void {
    $('#cover-spin').hide();
    $('.wrap-input100').removeClass("alert-validate");
  }

  Login() {

    $('.wrap-input100').removeClass("alert-validate");
    var usr = $("#usr").val();
    var psw = $("#psw").val();
    var flag = $("#ckb1").is(":checked") ? 1 : 0;
    if (usr && psw) {
      $('#cover-spin').show();
      let hash = Md5.hashStr(psw.toString());
      let data = {
        Username: usr,
        Password: hash
      };
      this.service.signIn(data).subscribe({
        next: data => {
          this.userdata = data;
          console.log(this.userdata[0])
          if (this.userdata[0]) {
            if(flag == 0){
              this.cookie.set('session', this.userdata[0].Username)
            }else{
              this.cookie.set('session', this.userdata[0].Username,14)
            }
            this.Toast.fire({
              icon: 'success',
             // title: 'Signed in successfully',
              title: 'Welcome '+this.userdata[0].Name+"!"
            })
            window.location.href = "/#/dashboard";
          } else {
            $('#cover-spin').hide();
            this.Toast.fire({
              icon: 'error',
              title: 'Incorrect Data!'
            })
          }

        },
        error: error => {
          this.userdata = error.message;
          $('#cover-spin').hide();
          this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
        }
      })
    } else {
      if (!psw)
        $('#divPsw').addClass("alert-validate");
      if (!usr)
        $('#divUsr').addClass("alert-validate");
        this.Toast.fire({
          icon: 'error',
          title: 'All fields are required!'
        })


    }
  }

}
