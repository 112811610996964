import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from "ngx-toastr";

import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule } from './shared/navbar/navbar.module';
import { FixedPluginModule } from './shared/fixedplugin/fixedplugin.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DetailsComponent } from './pages/details/details.component';
import { DetailsMultiComponent } from './pages/details-multi/details-multi.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';

import { SharedService } from './shared.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { DataTablesModule } from 'angular-datatables';
// import { HomeComponent } from './pages/home/home.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ProposteComponent } from './pages/affitti/proposte/proposte.component';
import { ProposteAggiungiComponent } from './pages/affitti/proposte-aggiungi/proposte-aggiungi.component';
import { ProposteModificaComponent } from './pages/affitti/proposte-modifica/proposte-modifica.component';
import { RichiesteComponent } from './pages/affitti/richieste/richieste.component';
import { RichiesteAggiungiComponent } from './pages/affitti/richieste-aggiungi/richieste-aggiungi.component';
import { RichiesteModificaComponent } from './pages/affitti/richieste-modifica/richieste-modifica.component';
import { AcquirentiComponent } from './pages/compravendite/acquirenti/acquirenti.component';
import { AcquirentiAggiungiComponent } from './pages/compravendite/acquirenti-aggiungi/acquirenti-aggiungi.component';
import { AcquirentiModificaComponent } from './pages/compravendite/acquirenti-modifica/acquirenti-modifica.component';
import { VenditoriComponent } from './pages/compravendite/venditori/venditori.component';
import { VenditoriAggiungiComponent } from './pages/compravendite/venditori-aggiungi/venditori-aggiungi.component';
import { VenditoriModificaComponent } from './pages/compravendite/venditori-modifica/venditori-modifica.component';
import { ImmobiliComponent } from './pages/immobili/immobili.component';
import { ImmobiliAggiungiComponent } from './pages/immobili/immobili-aggiungi/immobili-aggiungi.component';
import { ImmobiliModificaComponent } from './pages/immobili/immobili-modifica/immobili-modifica.component';
import { FullCalendarModule } from '@fullcalendar/angular'; 
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import { NgxDropzoneModule } from 'ngx-dropzone';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import { NgSelect2Module } from 'ng-select2';


FullCalendarModule.registerPlugins([ 
  dayGridPlugin,
  interactionPlugin,
  listPlugin,
  timeGridPlugin
  
]);

@NgModule({
  declarations: [
    AppComponent,
    DetailsComponent,
    DetailsMultiComponent,
    LoginComponent,
    RegisterComponent,
    CalendarComponent,
    ProfileComponent,
    HomepageComponent,
    NavbarComponent,
    ProposteComponent,
    ProposteAggiungiComponent,
    ProposteModificaComponent,
    RichiesteComponent,
    RichiesteAggiungiComponent,
    RichiesteModificaComponent,
    AcquirentiComponent,
    AcquirentiAggiungiComponent,
    AcquirentiModificaComponent,
    VenditoriComponent,
    VenditoriAggiungiComponent,
    VenditoriModificaComponent,
    ImmobiliComponent,
    ImmobiliAggiungiComponent,
    ImmobiliModificaComponent
  ],
  imports: [
    NgSelect2Module,
    NgxDropzoneModule,
    FullCalendarModule,
    DpDatePickerModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    DataTablesModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    NgxChartsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
