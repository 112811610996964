import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart } from 'chart.js';
import * as moment from 'moment';

@Component({
  selector: 'app-details-multi',
  templateUrl: './details-multi.component.html',
  styleUrls: ['./details-multi.component.css']
})
export class DetailsMultiComponent implements OnInit {
  
  IdDetail = 0;
  title = "";
  public canvas: any;
  public ctx;
  public data;
  public chart;
  public standard;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.params.subscribe((params) => {
      this.IdDetail = parseInt(params.IdDetail);
    });
  }

  graphClickEvent(event, array) {
    let a =window.location.href;
    if (array[0]) {
      window.location.href = "/#/dashboard/details-multi/escalator-availability;Day=" + array[0]._model.label + ";IdDetail=" + a.split("=")[1];

      // document.getElementById("redirecttonext").click(); 


    }
  }

  ngOnInit(): void {
    
    switch (this.IdDetail) {
      case 1:
        this.title = "Platform Screen Door Availability";
        break;
      case 2:
        this.title = "Escalator Availability";
        break;
      case 3:
        this.title = "Elevator Availability";
        break;
      case 4:
        this.title = "TIMO";
        break;
      case 5:
        this.title = "TIME";
        break;
      case 6:
        this.title = "TOMO";
        break;
      case 7:
        this.title = "TOME";
        break;
      case 8:
        this.title = "Automatic Gate";
        break;
    }

    this.canvas = document.getElementById("echart1");
    this.ctx = this.canvas.getContext("2d");

    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)',
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);

    this.canvas = document.getElementById("echart2");
    this.ctx = this.canvas.getContext("2d");
    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)',
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);

    this.canvas = document.getElementById("echart3");
    this.ctx = this.canvas.getContext("2d");
    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)',
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);

    this.canvas = document.getElementById("echart4");
    this.ctx = this.canvas.getContext("2d");
    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)', //RED
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'  //GREEN
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);


    this.canvas = document.getElementById("echart5");
    this.ctx = this.canvas.getContext("2d");
    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)',
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);


    this.canvas = document.getElementById("echart6");
    this.ctx = this.canvas.getContext("2d");
    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)',
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);


    this.canvas = document.getElementById("echart7");
    this.ctx = this.canvas.getContext("2d");
    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)',
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);


    this.canvas = document.getElementById("echart8");
    this.ctx = this.canvas.getContext("2d");
    this.data = {
      labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      datasets: [{
        label: 'Daily KPI',
        data: [93.7, 97.3, 96, 96, 95, 99],
        backgroundColor: [
          'rgba(214,97,71,0.5)',
          'rgba(252,226,81,0.51)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(214,97,71,0.5)',
          'rgba(59,204,66,0.5)'
        ],
        borderColor: [
          'rgba(214,54,54,1)', //RED
          'rgba(252,196,53,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(214,54,54,1)',
          'rgba(42,145,47,1)' //GREEN
        ],
        borderWidth: 1,
        stack: 'combined',
        type: 'bar'
      },
      {
        label: 'Cumulative KPI',
        data: [98, 94, 96, 96, 97, 95],
        backgroundColor: "rgba(73,100,252,1)",
        borderColor: 'rgba(73,100,252,1)',
        fill: false,
        tension: 0.2
      } ]
    };
    this.setData(this.data);
    this.chart = new Chart(this.ctx, this.standard);
  }

  setData(dat): void {
    var months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let currentMonth = parseInt(moment().format("M")) - 1;
    this.standard = {
      type: 'line',
      data: dat,

      options: {
        responsive: true,
        // onClick: function (c, i) {
        //   var e = i[0];
        //   console.log(e._index)
        //   var x_value = this.data.labels[e._index];
        //   var y_value = this.data.datasets[0].data[e._index];
        //   console.log(x_value);
        //   console.log(y_value);
        // },
        tooltips: {
          mode: "index",
          titleFontSize: 20,
          bodyFontSize: 17,
          callbacks: {
            title: function (t, d) {
              return ordinal(t[0].xLabel)+" "+months[currentMonth];
            },
            label: function (t, d) {
              if (t.datasetIndex === 0) {
                return 'Daily KPI: ' + t.yLabel.toFixed(2) + "%";
              } else if (t.datasetIndex === 1) {
                return 'Cumulative KPI: ' + t.yLabel.toFixed(2) + "%";
              }
            }
          }
        },
        scales: {

          xAxes: [{
            //     stacked: true, 
            gridLines: { display: false },
            scaleLabel: {
              display: true,
              labelString: 'Day of the current Month',
              fontSize: 12,
              family: 'Montserrat',
            },
            ticks: {
              stepValue: 2,
              steps: 15,
            }
          }],
          yAxes: [{
            //      stacked: true, 
            scaleLabel: {
              display: true,
              labelString: 'KPI [%]',
              fontSize: 12,
              family: 'Montserrat',
            },
            display: true,
            ticks: {
              steps: 5,
              stepValue: 2,
              max: 100,
              min: 90,
              callback: function (value) {
                return (value) + '%';
              }
            }
          }]
        }
      }
    };
    function ordinal(number) {
      switch (number) {
        case 1:
        case 21:
          return number + 'st';
        case 2:
        case 22:
          return number + 'nd';
        case 3:
        case 23:
          return number + 'rd';
        default:
          return number + 'th';
      }
    }
  }

}
