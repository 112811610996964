import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'app/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-venditori-modifica',
  templateUrl: './venditori-modifica.component.html',
  styleUrls: ['./venditori-modifica.component.css']
})
export class VenditoriModificaComponent implements OnInit {

  id: any;
  Region: any;
  Data: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  constructor(private service: ApiService, private route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      this.id = params['id'];
      console.log(this.id)
    });
  }

  ngOnInit(): void {
    this.data();
  }

  data(): void {
    let data = { IdSeller: this.id };

    this.service.getSellerById(data).subscribe({
      next: data => {
        this.Data = data;
        console.log(this.Data)
        this.region();
      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })
  }

  Update() {
    var nome = $("#nome").val();
    var cognome = $("#cognome").val();
    var tel = $("#tel").val();
    var email = $("#email").val();
    var reg = $("#region option:selected").val();
    var prov = $("#province option:selected").val();
    var citt = $("#city option:selected").val();
    var ind = $("#address").val();
    var desc = $("#desc").val();
    var request = $("#request").val();
    var esito = $("#esito").val();

    var pass = true;

    $(".req").each(function (index: any, value: any) {
      if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
        $(value).addClass("rederror");
        pass = false;
      } else {
        $(value).removeClass("rederror");
      }
    });
    if (pass != true) {
      this.Toast.fire({
        icon: 'error',
        title: 'Devi compilare tutti i campi!'
      })
      return;
    }

    let data = {
      Name: nome
      , Surname: cognome
      , Phone: tel
      , Email: email
      , Richiesta: request
      , Description: desc
      , Region: reg
      , Province: prov
      , City: citt
      , Address: ind
      , Result: esito
      , IdSeller : this.id
    }
    console.log(data)
    $('#cover-spin').show();
    this.service.updateSeller(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'success',
          title: 'Modidica effettuata con successo!'
        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }

  region() {
    this.service.getRegion().subscribe({
      next: data => {
        console.log(data)
        let cont = "<option>-- Nessuna Scelta --</option>";
        $.each(data, function (index: any, value: any) {
          cont += "<option value='" + value["IdRegion"] + "'>" + value["Name"] + "</option>";
        });
        $("#region").html(cont);
        $("#region").val(this.Data[0]["IdRegion"].toString());
        this.province1();

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })
  }


  province() {
    $("#city").html("<option>-- Nessuna Scelta --</option>")
    $("#city").attr('disabled', 'disabled');
    $("#province").removeAttr('disabled');
    let val = $('#region option:selected').val();
    console.log(val)
    if (val != "-- Nessuna Scelta --") {
      let data = { IdRegion: val }
      this.service.getProvince(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["IdProvince"] + "'>" + value["Name"] + "</option>";
          });
          $("#province").html(cont);

        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    } else {
      $("#province").html("<option>-- Nessuna Scelta --</option>")
      $("#province").attr('disabled', 'disabled');
      $("#city").html("<option>-- Nessuna Scelta --</option>")
      $("#city").attr('disabled', 'disabled');

    }

  }

  province1() {
    $("#city").html("<option>-- Nessuna Scelta --</option>")
    $("#city").attr('disabled', 'disabled');
    $("#province").removeAttr('disabled');
    let val = $('#region option:selected').val();
    console.log(val)
    if (val != "-- Nessuna Scelta --") {
      let data = { IdRegion: val }
      this.service.getProvince(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["IdProvince"] + "'>" + value["Name"] + "</option>";
          });
          $("#province").html(cont);
          $("#province").val(this.Data[0]["IdProvince"]);
          this.city1();
        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    } else {
      $("#province").html("<option>-- Nessuna Scelta --</option>")
      $("#province").attr('disabled', 'disabled');
      $("#city").html("<option>-- Nessuna Scelta --</option>")
      $("#city").attr('disabled', 'disabled');

    }

  }

  city() {
    $("#city").removeAttr('disabled');
    let val = $('#province option:selected').val();
    console.log(val)
    if (val != "-- Nessuna Scelta --") {
      let data = { IdProvince: val }
      this.service.getCity(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["City"] + "'>" + value["Name"] + "</option>";
          });
          $("#city").html(cont);
          
        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    }

  }

  city1() {
    $("#city").removeAttr('disabled');
    let val = $('#province option:selected').val();
    console.log(val)
    if (val != "-- Nessuna Scelta --") {
      let data = { IdProvince: val }
      this.service.getCity(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["City"] + "'>" + value["Name"] + "</option>";
          });
          $("#city").html(cont);
          $("#city").val(this.Data[0]["IdCity"]);
        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    }

  }

}
