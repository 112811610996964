import { Component, OnInit } from '@angular/core';
import { ApiService } from 'app/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-richieste-aggiungi',
  templateUrl: './richieste-aggiungi.component.html',
  styleUrls: ['./richieste-aggiungi.component.css']
})
export class RichiesteAggiungiComponent implements OnInit {

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2500,
    timerProgressBar: true
  })
  Region: any;
  constructor(private service: ApiService) { }

  ngOnInit(): void {
    this.region();
  }

  region() {
    this.service.getRegion().subscribe({
      next: data => {
        console.log(data)
        this.Region = data;

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Error!'
        })
      }
    })
  }


  province() {
    $("#city").html("<option>-- Nessuna Scelta --</option>")
    $("#city").attr('disabled', 'disabled');
    $("#province").removeAttr('disabled');
    let val = $('#region option:selected').val();
    console.log(val)
    if (val != "-- Nessuna Scelta --") {
      let data = { IdRegion: val }
      this.service.getProvince(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["IdProvince"] + "'>" + value["Name"] + "</option>";
          });
          $("#province").html(cont);

        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    } else {
      $("#province").html("<option>-- Nessuna Scelta --</option>")
      $("#province").attr('disabled', 'disabled');
      $("#city").html("<option>-- Nessuna Scelta --</option>")
      $("#city").attr('disabled', 'disabled');

    }

  }

  city() {
    $("#city").removeAttr('disabled');
    let val = $('#province option:selected').val();
    console.log(val)
    if (val != "-- Nessuna Scelta --") {
      let data = { IdProvince: val }
      this.service.getCity(data).subscribe({
        next: data => {
          let cont = "<option>-- Nessuna Scelta --</option>";
          $.each(data, function (index: any, value: any) {
            cont += "<option value='" + value["City"] + "'>" + value["Name"] + "</option>";
          });
          $("#city").html(cont);

        },
        error: error => {
          $('#cover-spin').hide();
          this.Toast.fire({
            icon: 'error',
            title: 'Error!'
          })
        }
      })
    }

  }

  Add() {
    var nome = $("#nome").val();
    var cognome = $("#cognome").val();
    var tel = $("#tel").val();
    var email = $("#email").val();
    var reg = $("#region option:selected").val();
    var prov = $("#province option:selected").val();
    var citt = $("#city option:selected").val();
    var ind = $("#address").val();
    var desc = $("#desc").val();
    var type = $("#type").val();
    var request = $("#request").val();
    var esito = $("#esito").val();

    var pass = true;

    $(".req").each(function (index: any, value: any) {
      if ($(value).val() == '' || $(value).val() == '-- Nessuna Scelta --') {
        $(value).addClass("rederror");
        pass = false;
      } else {
        $(value).removeClass("rederror");
      }
    });
    if (pass != true) {
      this.Toast.fire({
        icon: 'error',
        title: 'Devi compilare tutti i campi!'
      })
      return;
    }

    let data = {
      Name: nome
      , Surname: cognome
      , Phone: tel
      , Email: email
      , Tipology: type
      , Budget: request
      , Region: reg
      , Province: prov
      , City: citt
      , Address: ind
      , Features: desc
      , Result: esito
    }
    console.log(data)
    $('#cover-spin').show();
    this.service.insertRequests(data).subscribe({
      next: data => {
        $('#cover-spin').hide();
        Swal.fire({
          icon: 'success',
          title: 'Inserimento completato!',
          showConfirmButton: false,
          timer: 1500
        }).then((result) => {
          window.location.href = "/#/richieste";

        })

      },
      error: error => {
        $('#cover-spin').hide();
        this.Toast.fire({
          icon: 'error',
          title: 'Errore!'
        })
      }
    })

  }
}
